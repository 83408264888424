body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  padding-bottom: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  padding: 1rem 0;
}

h1, h2, h3, h4, h5, h6 {
  color: black;
}

.form-control {
  color: black;
}

/* #region  NAVBAR */
nav {
  font-weight: 500;
  margin-bottom: 1rem;
}

.navbar-brand {
  font-size: 1.50rem;
  font-weight: 600;
  text-transform: uppercase;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(33, 33, 33, 1);
}

.results-container {
  margin: 0;
  max-width: 100%;
}

.table {
  color: var(--dark);
}

.table td,
.table th {
  vertical-align: middle;
}

.table-info {
  font-size: 1.25rem;
}

#photo-preview {
  display: flex;
  margin: 1rem 0;
  flex-wrap: wrap;
  gap: 10px;
}

#photo-preview img {
  flex-basis: 32%;
  width: 200px;
}

.offerings-header {
  background-color: #6cc3d5;
  padding: 10px;
}

.potluck-header {
  background-color: #78c2ad;
  padding: 10px;
}

/* TODO: Fix positioning of 'Edit' button for shorter descriptions eg Hanh */
.table button {
  width: 100%;
}

.table tr td:last-child {
  padding: 0.5rem 0;
}

.table td {
  border-top: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

tr:focus {
  outline: none;
}

.wishlist-info {
  /* display: flex; */
  margin-top: 1rem;
}

@media (min-width: 900px) {
  .wishlist-info > p {
    width: 50%;
  }
}

.printed-wishlist {
  flex-grow: 2;
}

.printed-wishlist button {
  margin: 1rem 0;
}

.wishlist-table th{
  font-size: 1.5rem;
}
.wishlist-table th {
  border-top: none;
}

.wishlist-table th:first-child {
  width: 1rem;
}

.wishlist-table tr td:last-child {
  text-align: right;
  padding-right: 1rem;
}

.table tr td:last-child {
  width: 12%;
}

@media (max-width: 700px) {
  .table tr td:last-child {
    width: 22%;
  }
}

.wishlist-table img {
  width: 10vw;
  height: 10vw;
}


.offering {
  background-color: hsla(33, 82.5%, 49.4%, 0.37);
}

/* HACK: This overrides zen-table styles, until we don't use them anymore */
.offering:hover {
  background-color: hsla(33, 82.5%, 49.4%, 0.37) !important;
}

.potluck-item {
  background-color: hsla(118.1, 93.2%, 40.6%, 0.37);
  font-weight: 450;
}

.potluck-item:hover {
  background-color: hsla(118.1, 93.2%, 40.6%, 0.37) !important;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  padding: 0;
  flex-direction: column;
}

.gallery > li {
  border: 1px solid #222;
  flex-basis: 33vw;
  
  list-style: none;
  position: relative;
  overflow: hidden;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 400px;
  overflow: visible;
}

.gallery li img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.info {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid black;
  padding: 10px;
  overflow: hidden;
  width: 100%;
}

.actions {
  display: flex;
  position: absolute;
  bottom: 0;
  border-top: 1px solid black;
  column-gap: 1vw;
  background-color: rgba(255, 255, 255, 0.9);
  justify-content: space-evenly;
  padding: 10px;
  width: 100%;
}

.actions button {
  width: 10vw;
}

.btn {
  font-weight: 500;
}

.btn-warning {
  color: rgb(33,33,33);
}

.btn-text {
  margin-left: .5rem;
}

@media (max-width: 700px) {
  .btn-text {
    display: none;
  }
}

@media (min-width: 900px) {
  .gallery {
    flex-direction: row;
  }

  .gallery>li {
    flex-basis: 24vw;
    height: 30vw;
    overflow: hidden;
  }
}

.results {
  white-space: pre;
  font-family: monospace, Courier, Monaco;
}